import React from "react";
import { withTranslation, WithTranslation } from 'react-i18next';
import { withTheme, WithTheme } from '@mui/styles';
import { Link, Theme, Box, Card, CardContent, Stack, Typography} from "@mui/material";
import { AppContext } from '../AppContext';
import { withRouter, WithRouter } from "../common/router";
import BreadcrumbPath from "../components/BreadcrumbPath";
import { ContainerBox, PageStack } from "../components/styled";
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import AutoAwesomeMotionOutlinedIcon from '@mui/icons-material/AutoAwesomeMotionOutlined';
import ChromeReaderModeOutlinedIcon from '@mui/icons-material/ChromeReaderModeOutlined';
import ExplicitOutlinedIcon from '@mui/icons-material/ExplicitOutlined';
import QrCode2OutlinedIcon from '@mui/icons-material/QrCode2Outlined';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import InventoryIcon from '@mui/icons-material/Inventory';
import AdjustIcon from '@mui/icons-material/Adjust';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import EventNoteIcon from '@mui/icons-material/EventNote';
import AccountTreeIcon from '@mui/icons-material/AccountTree';

interface HomeProps extends WithTheme<Theme>, WithRouter, WithTranslation {

}


interface Menu {
    key:string;
    icon:JSX.Element;
    hidden?: boolean;
}
class Home extends React.Component<HomeProps> {
    public static contextType = AppContext;
    context!: React.ContextType<typeof AppContext>;

    constructor(props: HomeProps) {
        super(props);

        this.state = {
        };
    }

    public render() {
        const app = this.context;
        const { t } = this.props;

        const menus:{[key:string]:Menu[]} = {
            "NONTRADE": [
                    {
                        key:`/permenantcustody`,
                        icon:<AutoAwesomeMotionOutlinedIcon/>
                    },
                    {
                        key:`/stpermenantcustodypg`,
                        icon:<AccountTreeIcon/>,
                        hidden: app.client !== 'ADC' ? true:false
                    },        
                    {
                        key:`/stpermenantcustodywadifood`,
                        icon:<AccountTreeIcon/>,
                        hidden: app.client !== 'ADC' ? true:false
                    },        
                    {
                        key:`/stpermenantcustodychevron`,
                        icon:<AccountTreeIcon/>,
                        hidden: app.client !== 'ADC' ? true:false
                    },        
                    {
                        key:`/stpermenantcustodynestle`,
                        icon:<AccountTreeIcon/>,
                        hidden: app.client !== 'AFB' ? true:false

                    },        
                    {
                        key:`/stpermenantcustodyenergizer`,
                        icon:<AccountTreeIcon/>,
                        hidden: app.client !== 'ASC' ? true:false

                    },        
                    {
                        key:`/stpermenantcustodyjohnson`,
                        icon:<AccountTreeIcon/>,
                        hidden: app.client !== 'ASC' ? true:false

                    },        
                    {
                        key:`/temporarycustody`,
                        icon:<AccountTreeIcon/>
                    },        
                    {
                        key: `/closetemporarycustodypg`,
                        icon: <ChromeReaderModeOutlinedIcon/>,
                        hidden: app.client !== 'ADC' ? true:false

                    },
                    {
                        key: `/closetemporarycustodywadifood`,
                        icon: <ChromeReaderModeOutlinedIcon/>,
                        hidden: app.client !== 'ADC' ? true:false

                    },
                    {
                        key: `/closetemporarycustodychevron`,
                        icon: <ChromeReaderModeOutlinedIcon/>,
                        hidden: app.client !== 'ADC' ? true:false

                    },
                    {
                        key: `/othersuppliers`,
                        icon: <ExplicitOutlinedIcon/>
                    }
                ]
            ,
            
            "SUPPLIERS":[
                    {
                        key:`/mainsupplierspg`,
                        icon:<InventoryIcon/>,
                        hidden: app.client !== 'ADC' ? true:false
                    },
                    {
                        key:`/mainsupplierswadifood`,
                        icon:<LocalShippingIcon/>,
                        hidden: app.client !== 'ADC' ? true:false
                    },
                    {
                        key:`/mainsuppliersjohnson`,
                        icon:<LocalShippingIcon/>,
                        hidden: app.client !== 'ASC' ? true:false
                    },
                    {
                        key:`/mainsuppliersenergizer`,
                        icon:<AdjustIcon/>,
                        hidden: app.client !== 'ASC' ? true:false
                    },
                    {
                        key:`/mainsupplierschevron`,
                        icon:<AccountBalanceOutlinedIcon/>,
                        hidden: app.client !== 'ADC' ? true:false
                    },
                    {
                        key: `/mainsuppliersnestle`,
                        icon: <QrCode2OutlinedIcon/>,
                        hidden: app.client !== 'AFB' ? true:false
                    }
                ]
            ,
            "OPERATIONS": [
                    {
                        key:`/prepaidexpenses`,
                        icon:<AccountBalanceOutlinedIcon/>
                    },
                    {
                        key:`/assetcreate`,
                        icon:<AccountBalanceOutlinedIcon/>
                    },
                    {
                        key:`/payroll`,
                        icon:<ReceiptLongIcon/>
                    },
                    {
                        key:`/bounus`,
                        icon:<ReceiptLongIcon/>
                    },
                    {
                        key:`/rundepr`,
                        icon:<ReceiptLongIcon/>
                    },
                    {
                        key:`/monthlyaccrual`,
                        icon:<EventNoteIcon/>
                    },
                    {
                        key:`/ke5z`,
                        icon:<EventNoteIcon/>
                    }
                ]
            ,
            "DASHBOARDS": [
                    {
                        key:`/dashboard`,
                        icon:<AccountBalanceOutlinedIcon/>
                    },
                ]
            
        };

        return (
            <ContainerBox sx={{ display: 'flex', justifyContent: 'flex-start', margin: '0px' }}>
                <PageStack direction="column" sx={{ minWidth: '800px', display: 'inline-flex' }}>
                    <BreadcrumbPath crumbs={["/home"]} />
                    <Stack direction="column" sx={{ minWidth: '800px', display: 'inline-flex' }} gap={'2em'}>
                        {
                            (Object.entries(menus).map ((entry) => {
                                const [key, value] = entry;

                                return (
                                    <>
                                    <Typography variant="h5">{t(`${key}`)}</Typography>
                                    <Box sx={{display:'flex', flexDirection:'row', gap: '1em', flexWrap:'wrap'}}>
                                        {
                                            (value.map ((location, index) =>
                                                {
                                                    return ((location.hidden === undefined || location.hidden !== true) &&
                                                <Link key={index} color="primary" sx={{cursor:"pointer"}} onClick={() => app.handleNavigate (location.key)}>
                                                <Card sx={{ minWidth: 275}}>
                                                <CardContent sx={{display:'flex', gap:'1em'}}>
                                                    {
                                                        location.icon
                                                    }
                                                    <Typography sx={{ mb: 1.5, fontSize:'1.1em', textAlign: 'center' }} color="text.secondary">
                                                        {t(`${location.key}`)}                              
                                                    </Typography>
                                                </CardContent>
                                                </Card>
                                                </Link>
                                                    )
                                                 }
                                            ))
                                        }
                                    </Box>
                                    </>
                                )
                            }))
                        }
                    </Stack>
                </PageStack>
            </ContainerBox>
        );
    }
}

export default withTranslation()(withTheme(withRouter(Home)));